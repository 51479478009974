$left-navigation-width: 250px;
$left-navigation-with-icon-width: 53px;

div.panel-scaffold {
	padding-top: 60px;

	transition: all 0.5s;
}

@media only screen and (max-width: 600px) {
	.MuiDialog-paper {
		min-height: 80%;
	}
}

.old {
	&::after {
		content: "Yeni";
		padding: 5px;
		border-radius: 5px;
		background-color: yellowgreen;
		color: #122639;
		position: absolute;
		top: 10px;
		left: 174px;
		animation: color-new 3s infinite;
		&:hover {
			content: "asdxd";
			border: 1px solid red;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	&::before {
		content: "";
		width: 2px;
		height: calc(4.85vh + 2px);
		background-color: yellowgreen;
		position: absolute;
		top: 1px;
		left: 5px;
	}
}

#demo-multiple-checkbox-label {
	margin-left: 6px;
	color: #b2b2b2;
	&.Mui-focused {
		margin-top: 0 !important;
	}
}

.borderless-select {
	div {
		div {
			height: 1.1876em !important;
		}
	}
	.MuiInputLabel-shrink {
		margin-top: 0 !important;
	}
	// .MuiFormLabel-filled-28 {
	//   color: white !important;
	// }
	// .MuiOutlinedInput-root-40 {
	//   max-height: 55px;
	// }
	.Mui-focused {
		fieldset {
			border: 1px solid #b2b2b2 !important;
		}
	}
	.Mui-focused {
		p {
			margin-bottom: -5px !important;
		}
	}
	div {
		fieldset {
			legend {
				width: 0.01px !important;
			}
		}
	}
}

#demo-multiple-checkbox {
	&:before {
		border: none !important;
	}
	&:after {
		border: none !important;
	}
}

.appointment-date {
	border-radius: 6px;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
	.MuiInputBase-input {
		text-align: center;
		justify-content: center !important;
		color: #ed1e79;
		font-size: 20px !important;
		height: fit-content;
		margin-top: -15px !important;
	}
}

.padding-left {
	padding-left: 10px !important;
}

.padding-left.MuiFormLabel-filled {
	padding-top: 10px !important;
}

.appointment-add-dialog {
	.MuiDialog-paperScrollPaper {
		overflow: visible !important;
	}
}

div.top-bar {
	width: 100%;
	height: 60px;
	position: fixed;
	top: 0;
	display: flex;
	align-items: center;
	z-index: 100;
	background: white;
	box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04);

	.row > .col {
		display: flex;
		align-items: center;
	}

	.app-logo {
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		overflow: hidden;
		flex-wrap: nowrap;

		.first-logo {
			height: 53px;
			padding: 6px;
		}

		.second-logo {
			margin: auto 10px;
		}
	}

	.menu-right {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
	}
}

div.app-wrapper {
	display: flex;
	flex: 1;
	padding-left: $left-navigation-width;
	transition: all ease 0.25s;

	&.active {
		padding-left: $left-navigation-width;

		@media only screen and (max-width: 576px) {
			padding-left: 0;
		}
	}

	&.hide {
		padding-left: $left-navigation-with-icon-width;

		@media only screen and (max-width: 576px) {
			padding-left: 0;
		}
	}

	// &.blurred {
	//   filter: blur(8px);
	//   -webkit-filter: blur(8px);

	//   nav.left-navigation{
	//     transition: all 0s !important;
	//     top: 0 !important;
	//   }
	// }

	div.overlay {
		position: fixed;
		width: 5000%;
		height: 5000%;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 800;
		transition: all ease 0.5s;

		&.visible {
			visibility: visible;
			background: rgba($color: #000000, $alpha: 0.4);
		}

		&.hidden {
			visibility: hidden;
			background: rgba($color: #000000, $alpha: 0);
		}
	}

	nav.left-navigation {
		width: $left-navigation-width;
		height: calc(100vh - 60px);
		max-width: 80% !important;
		padding: 8px 0px;
		box-sizing: border-box;
		position: fixed;
		z-index: 1102;
		bottom: -100px;
		left: 0;
		top: 60px;
		overflow-y: auto;
		transition: all cubic-bezier(0.22, 0.61, 0.36, 1) 0.25s;

		&.active {
			width: $left-navigation-width;
		}

		&.hide {
			width: $left-navigation-with-icon-width;
		}

		@media only screen and (max-width: 576px) {
			width: 70%;

			&.hide {
				left: -70%;
			}

			.nav-item {
				padding: 0 8px !important;
			}
		}

		&::-webkit-scrollbar {
			display: none;
		}

		button {
			width: 100%;
			display: block;
			padding: 2px 5px 2px;

			.dark {
				color: rgb(237, 30, 121);
			}
			.light {
				color: #a07be5;
			}
			.purple {
				color: white;
			}

			.nav-item {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				background: transparent;
				height: 4.85vh;
				min-height: 40px;
				max-height: 60px;
				padding: 4px 15px 4px 8px !important;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				text-align: left;
				letter-spacing: -0.25px;
				text-decoration: none;
				transition: all 0.5s;
				border-radius: 6px !important;
				border-bottom-left-radius: 0px !important;
				border-top-left-radius: 0px !important;

				.MuiSvgIcon-root {
					font-size: 25px !important;
					float: left;
					margin-right: 10px;
				}

				span {
					line-height: 1.4;
					padding-left: 5px;
				}

				&.active {
					box-shadow: 0 0px 10px 1px rgba($color: #ffffff, $alpha: 0.3);
					border-radius: 3px;

					&::before {
						content: "";
						height: calc(4.85vh + 2px);
						width: 50px;
						// border-radius: 3px;
						position: absolute;
						top: 2;
						left: -47px;
						transition: all cubic-bezier(0.22, 0.61, 0.36, 1) 0.25s;
					}
				}

				&:hover {
					// width: $left-navigation-width;
					background: rgba(255, 255, 255, 0.6);
					box-shadow: 0 0px 10px 1px rgba($color: #ffffff, $alpha: 0.3);
					// border-left: 4px solid;
					// color: white;
					border-radius: 3px;
					transition: all cubic-bezier(0.22, 0.61, 0.36, 1) 0.25s;

					&::before {
						content: "";
						height: calc(4.85vh + 2px);
						width: 50px;
						background-color: #ed1e79;
						// border-radius: 3px;
						position: absolute;
						top: 2;
						left: -47px;
						transition: all cubic-bezier(0.22, 0.61, 0.36, 1) 0.25s;
					}
				}
			}

			.closed {
				&.active {
					&::before {
						content: "";
						left: -47px;
					}
				}
				&:hover {
					&::before {
						left: -47px;
					}
				}
			}

			.dark {
				color: rgb(237, 30, 121);
				&.active {
					background: rgba(237, 30, 121, 0.6);
					color: #fff;
					&::before {
						background-color: #ed1e79;
					}
				}
				&:hover {
					background-color: rgba(237, 30, 121, 0.6);
					color: #fff;
					&::before {
						background-color: #ed1e79;
					}
				}
			}
			.light {
				color: #a07be5;
				&.active {
					color: white;
					background: rgba(160, 123, 229, 0.6) !important;
					&::before {
						background-color: rgb(160, 123, 229);
					}
				}
				&:hover {
					color: white;
					background: rgba(160, 123, 229, 0.6) !important;
					&::before {
						background-color: rgb(160, 123, 229);
					}
				}
			}
			.purple {
				color: white;
				&.active {
					color: #a07be5 !important;
					background: rgba(255, 255, 255, 0.6);
					&::before {
						background-color: #fff;
					}
				}
				&:hover {
					color: #a07be5 !important;
					background: rgba(255, 255, 255, 0.6);
					&::before {
						background-color: #fff;
					}
				}
			}
		}
		.switchBox {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: center;
			width: 100%;
			margin-top: 30px;
			margin-bottom: 15px;
		}
	}

	div.panel-container {
		padding: 16px;
		width: 100%;
		min-height: calc(100vh - 60px);
		box-sizing: border-box;
		display: block;
		// overflow-x: hidden;
		// overflow-y: auto;
		transition: all 0.5s;

		div.panel-card {
			h2.page-caption {
				font-size: 23px;
				line-height: 2;
				font-weight: 600;
				margin-bottom: 16px;
			}
		}
	}

	.section-caption {
		font-size: 20px;
		margin-bottom: 16px;
	}
}

div.divider {
	position: relative;
	text-align: center;
	font-weight: 500;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	color: #707070;
	z-index: 1101;
	margin: 24px 0;

	span {
		position: relative;
		z-index: 1000;
		background: #fff;
		padding: 0 8px;
	}

	&::before {
		position: absolute;
		left: 0;
		width: 50%;
		height: 1px;
		background: #eaeaea;
		content: " ";
	}

	&::after {
		position: absolute;
		right: 0;
		width: 50%;
		height: 1px;
		background: #eaeaea;
		content: " ";
	}
}

ul.sub-navigation {
	width: 100%;
	position: relative;
	z-index: 10;
	margin: 0;
	padding: 0;
	overflow-y: auto;
	list-style: none;
	display: inline-flex;
	background: #fff;
	border-radius: 5px;
	// box-shadow: 0 4px 16px rgba(0, 0, 0, 0.06);

	/* &::-webkit-scrollbar {
    display: none;
  } */

	li {
		width: auto;
		flex: none;
		text-align: center;

		button {
			font-family: "Inter", sans-serif !important;
			font-size: 14px !important;

			a {
				font-family: "Inter", sans-serif !important;
				font-weight: 500;
				text-decoration: none;
				display: block;
				padding: 16px;
				line-height: 26px;
				color: #303030;

				@media only screen and (max-width: 576px) {
					padding: 16px 8px;
				}

				&.active {
					color: var(--primary);
					border-bottom: 2px solid var(--primary);
					background: #eaeaea;
				}
			}
		}

		.MuiIconButton-root {
			padding: 0px !important;
			margin-left: 5px;
		}
	}

	&::-webkit-scrollbar {
		height: 8px;
		border: 0;
		outline: 0;
		box-sizing: border-box;
		transition: all ease 0.35s;
	}

	&::-webkit-scrollbar-track {
		background: #efefef;
		border: 0;
		outline: 0;
		box-sizing: border-box;
		border-radius: 50px;
		transition: all ease 0.35s;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		height: 8px;
		background: #aaa;
		border: 0;
		outline: 0;
		box-sizing: border-box;
		border-radius: 50px;
		transition: all ease 0.35s;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: #a0a0a0;
		transition: all ease 0.35s;
	}
}

.card-section {
	padding-top: 8px;
	padding-bottom: 8px;
	box-shadow: 0 0 16px rgba(0, 0, 0, 0.04);
}

.Toastify__toast {
	@media screen and (min-width: 992px) {
		width: 500px;
		left: 50%;
		margin-left: -250px;
		box-shadow: none;
	}

	padding: 16px 24px;
	font-family: "Inter", sans-serif !important;
	font-weight: 500 !important;
	text-align: center;
	font-size: 14px;
	line-height: 1.4;
	letter-spacing: 0.2px;

	border-radius: 5px;
	text-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);

	&--default {
		color: #000 !important;
	}

	&--success {
		background: #339b36 !important;
	}

	&--warning {
		background: #ffaa00 !important;
	}

	&--error {
		background: #dd3e3e !important;
	}
}

.appointment-container {
	width: 90vw !important;
	overflow-x: hidden;
	overflow-y: hidden;

	div > div {
		&::-webkit-scrollbar {
			height: 8px;
			border: 0;
			outline: 0;
			box-sizing: border-box;
			transition: all ease 0.35s;
		}

		&::-webkit-scrollbar-track {
			background: #efefef;
			border: 0;
			outline: 0;
			box-sizing: border-box;
			border-radius: 50px;
			transition: all ease 0.35s;
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
			height: 8px;
			background: #aaa;
			border: 0;
			outline: 0;
			box-sizing: border-box;
			border-radius: 50px;
			transition: all ease 0.35s;
		}

		/* Handle on hover */
		&::-webkit-scrollbar-thumb:hover {
			background: #a0a0a0;
			transition: all ease 0.35s;
		}
	}
}
.headBar {
	align-items: center;
}

.appointment-add-dialog {
	.MuiDialog-paperScrollPaper {
		overflow: visible !important;
	}
}

.category-container_divider {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.category-container {
	width: 97%;
	margin-right: 5px;
	background-color: #f5f5f5;
	padding: 7px;
	border-radius: 3px;
	cursor: pointer;

	span {
		font-size: 17px;
		font-weight: bold;
		color: #ed1e79;
	}
}

.category-container_span {
	background-color: #f5f5f5;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 7px;
	border-radius: 3px;
	height: 28px;
	font-size: 17px;
	font-weight: bold;
	color: #ed1e79;
	cursor: pointer;
}

.active-category {
	background-color: #ed1e79 !important;
	span {
		color: #f5f5f5 !important;
	}
}

//To be used when the table structure changes

.MuiPagination-root {
	.MuiPagination-ul {
		flex-wrap: nowrap;
		li {
			&:first-child {
				flex-basis: 100%;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				> button::after {
					margin-left: 10px;
					content: "previous";
				}
			}
			&:last-child {
				flex-basis: 100%;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				> button::before {
					margin-right: 10px;
					content: "next";
				}
			}
		}
	}
}

.pagination {
	padding: 5px 0;
}

.pagination ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: flex;
	justify-content: center;
	align-items: center;
}

.pagination button {
	display: inline-block;
	padding: 10px 18px;
	color: #222;
}

.p7 button {
	width: 50px;
	height: 50px;
	border: none;
	border-radius: 2px;
	background-color: #f1f1f1;
	margin: auto 5px;
	color: #ed1e79;
	font-weight: bold;
	cursor: pointer;
	border-radius: 50px;

	li {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.p7 button:hover {
	background-color: #ddd;
	color: #ed1e79;
}

.p7 button:active {
	background-color: #f1f1f1;
	color: #ed1e79;
	transform: translateY(4px);
}

.p7 button:disabled {
	background-color: #ed1e79;
	color: #f1f1f1;
	transform: none;
	cursor: default;
}

.icon-back {
	margin-left: 6px !important;
}

.MuiButtonBase-root.time-add-button {
	height: 58px !important;
}

.demo-app {
	display: flex;
	min-height: 100%;
	font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
	font-size: 14px;
}

.demo-app-sidebar {
	width: 300px;
	line-height: 1.5;
	background: #eaf9ff;
	border-right: 1px solid #d3e2e8;
}

.demo-app-sidebar-section {
	padding: 2em;
}

.demo-app-main {
	flex-grow: 1;
	padding: 3em;
}

.fc {
	/* the calendar root */
	margin: 0 auto;
	.fc-view-harness {
		.fc-popover {
			z-index: 99 !important;
		}
	}
}

.color {
	width: 36px;
	height: 14px;
	border-radius: 2px;
}

.swatch {
	padding: 5px;
	background: #fff;
	border-radius: 1px;
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
	display: inline-block;
	cursor: pointer;
}

.popover {
	position: absolute;
	z-index: 2;
}

.cover {
	position: fixed;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
}
.menu {
	margin: auto;
	position: fixed;
	bottom: 60px;
	right: 60px;
	width: 80px;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	font-size: 37px;
	z-index: 9999;
}

.menu-item,
.menu-open-button {
	background: #eeeeee;
	border-radius: 100%;
	width: 60px;
	height: 60px;
	margin-left: -40px;
	position: absolute;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 80px;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	-webkit-transition: -webkit-transform ease-out 200ms;
	transition: -webkit-transform ease-out 200ms;
	transition: transform ease-out 200ms;
	transition: transform ease-out 200ms, -webkit-transform ease-out 200ms;
}

.menu-item:hover {
	background: #eeeeee;

	color: #ed1e79;
}

.menu-item:nth-child(3) {
	-webkit-transition-duration: 180ms;

	transition-duration: 180ms;
}

.menu-item:nth-child(4) {
	-webkit-transition-duration: 180ms;

	transition-duration: 180ms;
}

.menu-item:nth-child(5) {
	-webkit-transition-duration: 180ms;

	transition-duration: 180ms;
}

.menu-item:nth-child(6) {
	-webkit-transition-duration: 180ms;

	transition-duration: 180ms;
}

.menu-item:nth-child(7) {
	-webkit-transition-duration: 180ms;

	transition-duration: 180ms;
}

.menu-item:nth-child(8) {
	-webkit-transition-duration: 180ms;

	transition-duration: 180ms;
}

.menu-item:nth-child(9) {
	-webkit-transition-duration: 180ms;

	transition-duration: 180ms;
}

.menu-open-button {
	z-index: 2;
	-webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
	transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
	-webkit-transition-duration: 400ms;
	transition-duration: 400ms;
	-webkit-transform: scale(1.1, 1.1) translate3d(0, 0, 0);
	transform: scale(1.1, 1.1) translate3d(0, 0, 0);
	cursor: pointer;
	box-shadow: 3px 3px 0 0 rgba(58, 58, 58, 0.14);
}

.menu-open-button:hover {
	-webkit-transform: scale(1.2, 1.2) translate3d(0, 0, 0);

	transform: scale(1.2, 1.2) translate3d(0, 0, 0);
}

.menu-open:checked + .menu-open-button {
	-webkit-transition-timing-function: linear;

	transition-timing-function: linear;

	-webkit-transition-duration: 200ms;

	transition-duration: 200ms;

	-webkit-transform: scale(0.8, 0.8) translate3d(0, 0, 0);

	transform: scale(0.8, 0.8) translate3d(0, 0, 0);
}

.menu-open:checked ~ .menu-item {
	-webkit-transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);

	transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
}

.menu-open:checked ~ .menu-item:nth-child(3) {
	transition-duration: 180ms;

	-webkit-transition-duration: 180ms;

	-webkit-transform: translate3d(0.08361px, -84.99997px, 0);

	transform: translate3d(0.08361px, -84.99997px, 0);
}

.menu-open:checked ~ .menu-item:nth-child(4) {
	transition-duration: 580ms;

	-webkit-transition-duration: 580ms;

	-webkit-transform: translate3d(-70.86291px, 32.62064px, 0);

	transform: translate3d(-70.86291px, 40.62064px, 0);
}

.menu-open:checked ~ .menu-item:nth-child(5) {
	transition-duration: 680ms;

	-webkit-transition-duration: 680ms;

	-webkit-transform: translate3d(-81.03006px, -52.33095px, 0);

	transform: translate3d(-81.03006px, -52.33095px, 0);
}

.blue {
	background-color: #669ae1;
	box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.1);
	text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.blue:hover {
	color: #669ae1;

	text-shadow: none;
}

.green {
	background-color: #70cc72;
	box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.1);
	text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.green:hover {
	color: #70cc72;

	text-shadow: none;
}

.red {
	background-color: #fe4365;

	box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.1);

	text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.red:hover {
	color: #fe4365;

	text-shadow: none;
}

.agr-checkbox {
	accent-color: #3189ec;
	width: 18px;
	height: 18px;
}

.no-scroll-bar {
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.no-scroll-bar::-webkit-scrollbar {
	display: none;
}

.button-hover-note-add {
	border: 1px solid red;
}

.button-hover-note-add::before {
	content: "Note Ekle" !important;
	visibility: hidden !important;
	opacity: 0 !important;
	width: 50px !important;
	background-color: #ccc !important;
	color: #4f4f4f !important;
	text-align: center !important;
	border-radius: 5px !important;
	padding: 5px !important;
	transition: opacity 1s ease-in-out !important;

	position: absolute !important;
	z-index: 1000 !important;
	left: -50% !important;
	top: -50% !important;
}

.button-hover-note-add:hover::before {
	opacity: 1 !important;
	visibility: visible !important;
}

.ReactCrop__image {
	max-width: 100%;
	max-height: 800px;
}

.detail-search {
	border-radius: 5px;
	padding: 5px 5px 0 5px;
	box-shadow: 0 3px 3px rgba(0, 0, 0, 0.16);
	transition: all 0.2s ease;
	cursor: pointer;
}

.detail-search:active {
	transition: all 0.2s ease;
	transform: scale(0.9);
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
	cursor: pointer;
}

#countdown {
	position: relative;
	margin: auto;
	margin-top: 12px;
	height: 40px;
	width: 40px;
	text-align: center;
}

#countdown-number {
	color: black;
	display: inline-block;
	line-height: 40px;
}

#countdown svg {
	position: absolute;
	top: 0;
	right: 0;
	width: 40px;
	height: 40px;
	transform: rotateY(-180deg) rotateZ(-90deg);
}

#countdown svg circle {
	stroke-dasharray: 113px;
	stroke-dashoffset: 0px;
	stroke-linecap: round;
	stroke-width: 2px;
	stroke: black;
	fill: none;
	animation: countdown 30s linear forwards;
}

.sms_btn_hover:hover {
	opacity: 0.8;
}
.list-container {
	max-height: 267px; /* Liste yüksekliğinin sınırı */
	overflow: hidden;
}

.list-container.open {
	max-height: none; /* Dropdown açıldığında sınırsız yükseklik */
}

.enter_to_branch_btn{
	padding: 10px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 4px;
	background-color: rgb(226, 226, 248);
	color:rgb(93, 93, 211);
	border-radius: 10px;
	border:none;
	font-size: 14px;
	
}
.enter_to_branch_btn:hover{
	opacity: 0.8;
}

.branch_change_btn{
	margin-top: 30px;
	padding: 10px;
	border: 8px;
	cursor: pointer;
	background-color: rgb(224, 224, 255);
	color:rgb(38, 38, 245);
}
.branch_change_btn:hover{
	opacity: 0.8;
}
.branch_list{
	width: 420px;
	overflow: auto;
	&::-webkit-scrollbar {
		display: none;
	}

}

.platinium-diamond-button:hover{


box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2) !important;


}
.login_btns_wrapper {
	display: flex;
	margin-top: 8px;
}
.google_button_wrapper {
	flex: 1;
	border-radius: 30px;
	background-color: white;
	color: black;
	display: flex;
	gap: 12px;
	align-items: center;
	padding: 6px;
	cursor: pointer;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
	margin-right: 8px;
}

.google_button_wrapper img {
	width: 30px;
	height: auto; /* Maintain aspect ratio */
	max-width: 100%; /* Ensure it doesn't exceed the container width */
}

.google_button_wrapper div {
	height: 20px;
}
.google_button_wrapper div p {
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
}
.google_button_wrapper:hover {
	opacity: 0.8;
}
/* Media query for smaller screens */

@media (max-width: 768px) {
	.branch_list{
		width: auto;
		overflow: auto;
		&::-webkit-scrollbar {
			display: none;
		}
	
	}
	.login_btns_wrapper{
	
		gap: 5px;
	}
	.google_button_wrapper {
		width: 50%;
		display: flex;
		justify-content: center;
	}
	.google_button_wrapper div {
		display: none;
	}
	.google_button_wrapper div p {
		font-size: 12px;
	}
}
@media (min-width: 768px) {
	.google_button_wrapper div p {
		font-size: 12px;
	
	}

}
@media (max-width: 1450px) {
	.google_button_wrapper {
		width: 50%;
		display: flex;
		justify-content: center;
	}
	.google_button_wrapper div {
		display: none;
	}
	.google_button_wrapper img {
		width: 25px !important;
	}

	
}
@media (min-width: 1400px) {
	.google_button_wrapper div p {
		font-size: 15px;
	}
}

@keyframes countdown {
	from {
		stroke-dashoffset: 0px;
	}
	to {
		stroke-dashoffset: 113px;
	}
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
}

.input-phone-number input:focus{
	outline: none !important;
	border:none !important;
	box-shadow: 0px 1px 15px 4px #f8f8f8 !important;
}

.react-tel-input .form-control{
	box-shadow: 0px 1px 15px 4px #f8f8f8 !important;
}